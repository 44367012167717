.ant-switch-checked {
  background-color: #0d0d0d !important;
}

.page-item.active .page-link {
  background-color: #0d0d0d;
  border-color: #0d0d0d;
}

.page-link {
  color: #767676;
}

.MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  display: none !important;
}

.MuiDataGrid-overlayWrapper {
  min-height: 10rem;
}

.friend-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .friend-sub {
    width: 97%;
    height: 7rem;
    background-color: #ffffff;
    border: 1px solid #efefef;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .friend-content {
      width: 94%;
      height: 100%;
      display: flex;
      gap: 10px;
      justify-content: start;
      align-items: center;
      .friend-image {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
      .friends-details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: start;
        .f-name {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          line-height: 26.21px;
          text-align: start;
          color: #0d0d0d;
        }
        .f-university {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.63px;
          text-align: start;
          color: #999999;
        }
      }
    }
  }
}

.user-info {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  color: #0d0d0d;
}
