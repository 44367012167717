.post {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 16px;
    margin: 16px;

}

.post-header {
    display: flex;
    align-items: center;
}

.profile-pic {
    border-radius: 50%;
    width: 46px;
    height: 46px;
}

.post-info {
    margin-left: 10px;
}

.username {
    font-weight: bold;
}

.post-time,
.post-location {
    color: #888;
}

.post-content {
    margin-top: 16px;
}

.post-content .mention {
    // color: #0073e6;
    text-decoration: none;
}

.post-slider {
    margin-top: 16px;

    .slick-slider {
        height: 350px;
    }
}

.image_container_slider {
    width: 100%;
    height: 350px;
    border-radius: 8px;

}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.post-footer {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.post-actions span {
    margin-right: 16px;
    color: #555;

    svg {
        width: 20px;
        cursor: pointer;
    }
}

#scrollableDiv::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrollableDiv {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}