.comment {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    flex: 1;
    font-family: Poppins;

    &_username{
        font-size: 14px;
        font-weight: 600;
        color: #0D0D0D;

        span{
            font-weight: 400;
            color: #999999;
        }
    }

    &_reply{
        font-size: 12px;
        font-weight: 400;
        color: #999999;

        span{
            font-weight: 600;
            color: #0D0D0D;
        }
    }

    &_content{
        color: #0D0D0D;
        font-size: 12px;
        font-weight: 400;
    }

    &__header {

        &__profile-pic {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
        }

        &__user-info {
            display: flex;
            flex-direction: column;

            &__username {
                font-weight: bold;
            }

            &__reply-time {
                color: #666;
            }
        }
    }

    &__body {
        margin-top: 10px;

        &__replying-to {
            color: #666;

            a {
                color: #007bff;
                text-decoration: none;
            }
        }

        &__text {
            margin-top: 5px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-top: 10px;

        &__likes,
        &__replies {
            margin-right: 15px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }

        &__delete-btn {
            margin-left: auto;
            background: none;
            border: none;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}